import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import style from './style.module.scss'
import { useTranslation } from "react-i18next";
import { TranslationConstants } from '../../Constants'
import FeaturedProductHeader from '../FeaturedProductHeader'
import Scrollable from '../Scrollable'
import ViewMore from '../ViewMoreProducts'
import Copy from '../Copy'
import { navigate } from "gatsby";
import { LoadingScreen } from '../';
import Footer from '../Footer'

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const SingleProduct = (props) => {

    const { t } = useTranslation();
    const previousId = usePrevious(props.search.id);
    const [isAnimating, setIsAnimating] = useState(false);
    useEffect(() => {
        if (previousId !== props.search.id && previousId != null)
            document.getElementById(`scrolling`).scrollTop = 0
    }, [props.search])

    useEffect(() => {
        if (!props.isLoading && !props.product) navigate('/')
    }, [props.product, props.isLoading])

    return props.isLoading || !props.product ? <LoadingScreen /> : (
        <div className={style.featuredContainer}>
            <Scrollable id={`single-product-${props.search.id}`}>
                <div id="product-container" className={style.contentWrapper}>
                    <FeaturedProductHeader handleAnimationStatus={setIsAnimating} index={props.search.id} />
                </div>
                <ViewMore index={props.search.id} isAnimating={isAnimating} color={"blue"} header={t(TranslationConstants.viewMoreProducts).toUpperCase()} background={"white"} />
                <Footer />
            </Scrollable>
        </div>
    )
}

export default connect((state, { search }) => {
    return ({
        isLoading: (state.getRecipesLoading || state.getProductsLoading),
        product: state.products[+search.id]
    })
})(SingleProduct)
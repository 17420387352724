import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap';
import * as Actions from '../../state/constants';
import { LoadingScreen } from '../';
import NoImage from '../NoImage';
import shadow from '../../images/shadow.png'
import Arrow from '../../images/white_arrow.png'
import { useTranslation } from "react-i18next";
import DashLines from '../../images/3_dash_lines.png'
import style from './style.module.scss'
import { TranslationConstants } from '../../Constants';

const FeaturedProductHeader = (props) => {
    const { t } = useTranslation();
    useEffect(() => {
        if (!props.getProductsLoading) {
            props.handleAnimationStatus(true)

            TweenMax.to("#product-name", 0.6, { opacity: 1.0 })
            TweenMax.from("#product-name", 1, { y: 50 });
            TweenMax.from("#product-name", 0.6, { x: -500 })

            props.grabber && TweenMax.to("#product-grabber", 0.6, { opacity: 1.0 })
            props.grabber && TweenMax.from("#product-grabber", 0.6, { x: 500, delay: 0.6 })

            TweenMax.from("#product-image", 0.6, { opacity: 0.0, y: 200, delay: 1.0 })
            TweenMax.to("#product-image", 0.6, { opacity: 1.0, delay: 1.0 })
            TweenMax.to("#product-shadow", 0.6, { opacity: 1.0, delay: 1.0 })

            setTimeout(() => { props.handleAnimationStatus(false) }, 1600)
        }
    }, [props.product])

    const handleNutritionalFactsClick = (e) => {
        e.preventDefault();
        props.dispatch({ type: Actions.SET_NUTRI_FACTS_IMAGE, src: nutritionalImage })
        props.dispatch({ type: Actions.TOGGLE_NUTRITION, bool: true })
    }

    let { grabber, name, photos } = props.product
    let productPhotoTypeId = photos.filter(photo => photo.photo_type_id === 1)
    let productPhoto = productPhotoTypeId[0] && productPhotoTypeId[0].location

    const nutritionalImage = props.product && props.product.photos && props.product.photos.reduce((f, c) => c.photo_type_id === 2 ? c : f, null)

    return (
        <div className={style.pageHeaderWrapper}>
            {
                props.getProductsLoading ? <LoadingScreen /> :
                    <div className={style.sections} style={!grabber ? { 'max-width': '1000px' } : null}>
                        <div id="product-name" className={`${style.sectionWrapper} ${style.headerLeftWrapper}`}>
                            {name ?
                                <div className={style.productName}>
                                    <img alt="dash-lines" className={style.dashLines} src={DashLines} />
                                    <h2 className={style.headerLeft}>{name}</h2>
                                    {/* <div className={style.nutritionalButtonWrapper}>
                                        {nutritionalImage && <button onClick={handleNutritionalFactsClick}>{t(TranslationConstants.viewNutritionalFacts)}</button>}
                                    </div> */}
                                </div> : null
                            }
                        </div>
                        <div className={`${style.sectionWrapper} ${style.centerWrapper}`}>
                            <div className={style.imageWrapper}>
                                {photos[0] ?
                                    <div className={style.images}>
                                        <img alt={`product-${name}`} id="product-image" className={style.image} src={productPhoto || <NoImage />} />
                                        <div className={style.containingWrapper}>
                                            <img id="product-shadow" className={style.shadow} src={shadow} />
                                        </div>
                                    </div> : null
                                }
                            </div>
                        </div>
                        {grabber ?
                            <div className={`${style.sectionWrapper} ${style.headerRightWrapper}`}>
                                <div id="product-grabber" className={style.grabberWrapper}>
                                    <img src={Arrow} />
                                    <h2>{grabber}</h2>
                                </div>
                            </div>
                            : null}
                    </div>

            }

        </div>
    )
}

FeaturedProductHeader.propTypes = {

}

FeaturedProductHeader.defaultProps = {

}

export default connect((state, { index }) => {
    return ({
        getProductsLoading: state.getProductsLoading,
        product: state.products[+index] || {}
    })
})(FeaturedProductHeader)
import React from 'react'
import Layout from '../components/layout'
import { QueryParams } from '../components'
import Single from '../components/SingleProduct'

const SingleProduct = (props) => {
    return (
        <Layout>
            <Single {...props} />
        </Layout>
    )
}

export default QueryParams(SingleProduct);